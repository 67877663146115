import "./Sidebar.css";
import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";

import logo from "../images/abhi.jpg";
import github from "../images/github.png";
import instagram from "../images/instagram.png";
import twitter from "../images/twitterx.png";
import linkedin from "../images/linkedin.png";
import gmail from "../images/gmail.png";

export default function Sidebar() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3,
      },
    },
  };
  const iconList = [
    {
      component: github,
      href: "https://github.com/abhisek9898",
    },
    {
      component: linkedin,
      href: "https://www.linkedin.com/in/abhisek-malla-387b19290/",
    },
    {
      component: twitter,
      href: "https://x.com/AbhisekMal28496",
    },
    {
      component: instagram,
      href: "https://www.instagram.com/abhisek_malla00/?hl=en",
    },
    {
      component: gmail,
      href: "mailto:abhi.malla16@gmail.com",
    },
  ];
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="sidebar">
      <div className="topHashtag"># WebWizardry</div>
      <div className="topHashtag"># FullStackForce</div>
      <div className="topHashtag"># ReactRanger</div>
      <h1>
        <Link smooth to="/#start" className="h1_links">
          Abhisek Malla
        </Link>
      </h1>
      <motion.div
        animate={{ y: [2, -2] }}
        transition={{
          ease: "linear",
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <img src={logo} className="my-img" alt="myImage" />
      </motion.div>
      <p style={{ color: "black", fontWeight: "bold" }} className="gmail">
        <a
          href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJftvBffjPmxWxvfHWRHWnhTbpQgPxBxvkcnPpFpFqbhrXMCHXSJTsCXCVLsQzgRcVLpnPL"
          rel="noreferrer"
          target="_blank"
          className="fa fa-envelope"
          aria-label="Send email to abhi.malla16@gmail.com"
        ></a>
        &nbsp;abhi.malla16@gmail.com
      </p>

      <ul className="sidebar-nav">
        <li className="sidebar-nav-items">
          <Link smooth to="/#projects" className="links">
            Projects
          </Link>
        </li>
        <li className="sidebar-nav-items">
          <Link smooth to="/#about" className="links">
            About
          </Link>
        </li>
        <li className="sidebar-nav-items">
          <Link smooth to="/#interest" className="links">
            Interest
          </Link>
        </li>
        <li className="sidebar-nav-items">
          <Link smooth to="/#education" className="links">
            Education
          </Link>
        </li>
      </ul>

      <div>
        <motion.ul
          className="sidebar-nav"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {iconList.map((val, index) => (
            <motion.li key={index} variants={item}>
              <img
                onClick={() => {
                  window.open(val.href, "_blank");
                }}
                src={val.component}
                className="icon-img"
                alt="icon"
              />
            </motion.li>
          ))}
        </motion.ul>
      </div>
      <div
        style={{
          color: "black",
          fontWeight: "bold",
          paddingTop: "9%",
        }}
        className="tagtop"
      >
        Made with{" "}
        <button className="fa fa-heart heart" aria-label="Love"></button> by
        Abhi.
      </div>
    </div>
  );
}
